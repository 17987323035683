import React, { Component } from 'react';
import PropTypes from "prop-types";
import {mixContentExports} from '../Site-Content/mixContentArray';
import '../Stylesheets/App.css';
import { withRouter } from "react-router-dom";

export class DesktopMixDetails extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props)
    
    this.state = {
    }
  }
    render() {
          const { match, location, history } = this.props;
          return (            
            <div id="mixwindowwrapper">
                <div className="MixWindow" id="mwtopleft">
                    <button class="MixTitleButton" onClick={() => this.mixHome()}> MIXES </button>
                </div>
                <div className="MixWindow" id="mwtopright">
                    <div id="mixdetails">
                        <span className="MWTitle" id="mixdetailstitle" />         
                        <button id="mixselectbutton" />
                        <div id="mixdescription" />
                        <p />
                        <div id="artistlink"></div>
                        <p />
                        <div id="moremixes" onClick={() => this.mixHome()}> Hear More Mixes... </div>
                        <p />
                        
                    </div>
                </div>
                <div className="MixWindow" id="mwbottomleft">
                  <div id="miximagewindow"></div>
                </div>
                <div id="mixlistcontainer" />
                <div className="MixWindow" id="mwbottomright" />           
                
          </div>
        )        
    }
    
    componentDidMount() {  
      this.setState({mixid: mixContentExports.indexOf(mixContentExports.find(item => item.artist ===this.props.location.pathname.replace(/\/mixes\//g, '').replace(/-/g, ' ')))},
      function(){
      if(!this.state.mixListInit){
        this.initMixDetails();
      }
      else{
        document.getElementById('mixlistcontainer').appendChild(this.state.genMixList);
      }      
      let trishLogo = document.getElementById('trishlogo');
      trishLogo.style.width = '8vw';
      trishLogo.addEventListener('click', () => this.goHome.bind(this));
    }
      )
    }
    componentWillUnmount() {
      let trishLogo = document.getElementById('trishlogo');
      trishLogo.style.width = '12vw';
      trishLogo.removeEventListener('click', () => this.goHome());
    }
    //load desktop details
    initMixDetails(){

      //find entry in array
      let artistName = this.props.location.pathname.replace(/\/mixes\//g, '');
      artistName = artistName.replace(/-/g, ' ');
      let mixDetailsArray = mixContentExports.find(item => item.artist ===artistName);
      let mixDetails = document.getElementById('mixdetails');
      let mixImageWindow = document.getElementById('miximagewindow');
      //get rid of old select mix button & hide mix home page title and list
      document.getElementById('mixselectbutton').remove();
      //document.getElementById('mixlist').style.display = 'none';
      //set artist title, description text & image
      document.getElementById('mixdetailstitle').innerHTML = mixDetailsArray.artist;
      document.getElementById('mixdescription').innerHTML = mixDetailsArray.description;
      document.getElementById('artistlink').innerHTML =  'More from ' + mixDetailsArray.artist;
      document.getElementById('artistlink').addEventListener('click', () => { window.location = mixDetailsArray.artistlink; }, false);
      mixImageWindow.innerHTML = '';
      let mixImage = document.createElement('img');
      this.setAttributes(mixImage, {'id': 'miximage', 'src': mixDetailsArray.imagelarge.default, 'srcset': mixDetailsArray.imagesmall.default + ' 288w, ' + mixDetailsArray.imagelarge.default + ' 576w', 'sizes': '30vw'})
      mixImageWindow.appendChild(mixImage);
      document.getElementById('mwbottomleft').appendChild(mixImageWindow);
      //add new select mix button
      let mixSelectButton = document.createElement('button');
      this.setAttributes(mixSelectButton, {'class': 'MixSelectButton', 'id': 'mixselectbutton', 'mixid': mixContentExports.indexOf(mixDetailsArray)})
      mixSelectButton.addEventListener('click', (e) => {this.mixAudioSelect(e)});
      mixDetails.insertBefore(mixSelectButton, mixDetails.childNodes[1]);
      //show mix details & image(put image in)
      mixDetails.style.display = 'grid';
      //mixImageWindow.style.display = 'grid';
      //reset scroll
      document.getElementById('mwtopright').scrollTop = 0;
      document.getElementById('mwbottomleft').scrollTop = 0;
    }
  //Play Selected Mix Audio
  mixAudioSelect(e){
    let mixid = e.target.getAttribute('mixid');
    this.music = document.getElementById('audiotag');
    //let mobileRule = window.matchMedia("all and (any-hover: none)");
    //set new audio source
    this.music.pause();
    this.music.src = mixContentExports[mixid].mixfile.default;
    //this really needs fixing
    this.props.changeMix(mixid);
    //document.getElementById('pButton').click();
  }
  mixHome(){
  
    this.props.history.push('/mixes');
  }
  goHome(){

    this.props.history.push('/');
  }
    
  //HELPER FUNCTIONS
  setAttributes(el, attrs) {
    for(var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }
}

export default withRouter(DesktopMixDetails)
