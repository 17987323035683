
import React, { Component } from "react";
import { Shaders, Node, GLSL } from "gl-react";
import { Surface } from "gl-react-dom";

export const shaders = Shaders.create({
  bgVisuals01: {
    frag: GLSL`
      
      //Shader based on Liquid Spectrum by ryk https://www.shadertoy.com/view/4sjXRG

      precision highp float;
      varying vec2 uv;
      uniform float timer;
      uniform float bass;
      uniform float mid;
      uniform float hi;
      uniform float randommult;

      float noise(in vec2 p)
        { return sin(p.x*10.) * sin(p.y*(3. + sin(timer/11.))) + 1.2 * mid; }

      mat2 rotate(float angle)
        { return mat2(cos(angle), -sin(angle), sin(angle), cos(angle)*sin(mid*(0.3 + sin(timer)))); }

      float fbm(vec2 p) {
        p *= 1.1;
        float f = 0.;
        float amp = .5 + (.2 * bass);
        
        for( int i = 0; i < 3; i++) {
          mat2 modify = rotate(timer/50. * float(i*i));
          f += amp*noise(p);
          p = modify * p;
          p *= 2.;
          amp /= 2.2;
        }

        return f;
      }

      float pattern(vec2 p, out vec2 q, out vec2 r) {
        q = vec2( fbm(p + vec2(1.)),
        fbm(rotate(.1*timer)*p + vec2(3.)));
        r = vec2( fbm(rotate(.2)*q + vec2(0.)),
        fbm(q + vec2(0.)));
        
        return fbm(p + 1.*r);
      }

      vec3 hsv2rgb(vec3 c) {
        vec4 K = vec4(0.6 * bass, 1.0 / 2.5, 1.0 / 3.0, 3.0);
        vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
        
        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
      }

      void main() {
        vec2 p = uv.xy;
        float ltime = timer;
        float ctime = timer + fbm(p/(8.))*40.;
        float ftime = fract(ctime/6.);
        ltime = floor(ctime/6.) + (1.-cos(ftime*3.1415)/2.);
        ltime = ltime*6.;
        vec2 q;
        vec2 r;
        float f = pattern(p*(mid*randommult), q, r);
        vec3 col = hsv2rgb(vec3(q.x/10. + ltime/100. + .4, abs(r.y)*3. + .1, r.x + f *hi ));
        float vig = 1. - pow(4.*(p.x - .5)*(p.x - .5), 10.);
        vig *= 1. - pow(4.*(p.y - .5)*(p.y - .5), 10.);
        
        gl_FragColor = vec4(col*vig,1.);
      }`
  },
  bgVisuals02: {
    frag: GLSL`
    
      //Shader based on Liquid Spectrum by ryk https://www.shadertoy.com/view/4sjXRG

      precision highp float;
      varying vec2 uv;
      uniform float timer;
      uniform float bass;
      uniform float mid;
      uniform float hi;
      uniform float randommult;

      float noise(in vec2 p)
        { return sin(p.x*10.) * sin(p.y*(3. + sin(timer/11.))) + .2 * mid; }

      mat2 rotate(float angle)
        { return mat2(cos(angle), -sin(angle), sin(angle), cos(angle)*sin(mid*(3. + sin(timer)))); }

      float fbm(vec2 p) {
        p *= 1.1;
        float f = 0.;
        float amp = .1 + (.2 * bass);
        
        for( int i = 0; i < 3; i++) {
          mat2 modify = rotate(timer/50. * float(i*i));
          f += amp*noise(p);
          p = modify * p;
          p *= 2.;
          amp /= 2.2;
        }
        
        return f;
      }

      float pattern(vec2 p, out vec2 q, out vec2 r) {
        q = vec2( fbm(p + vec2(1.)),
        fbm(rotate(.1*timer)*p + vec2(3.)));
        r = vec2( fbm(rotate(.2)*q + vec2(0.)),
        fbm(q + vec2(0.)));
        
        return fbm(p + 1.*r);
      }

      vec3 hsv2rgb(vec3 c) {
        vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
        vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
        
        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
      }

      void main() {
        vec2 p = uv.xy;
        float ltime = timer;
        float ctime = timer + fbm(p/(8.*randommult))*40.;
        float ftime = fract(ctime/6.);
        ltime = floor(ctime/6.) + (1.-cos(ftime*3.1415)/2.);
        ltime = ltime*6.;
        vec2 q;
        vec2 r;
        float f = pattern(p*(mid*randommult), q, r);
        vec3 col = hsv2rgb(vec3( .3 + ltime/10. + .4, abs(r.y)*3. + .1, r.x + f *hi ));
        float vig = 1. - pow(4.*(p.x - .5)*(p.x - .5), 10.);
        vig *= 1. - pow(4.*(p.y - .5)*(p.y - .5), 10.);

        gl_FragColor = vec4(col*vig,1.);
      }`
  }

});

const shaderObject = Object.values(shaders);

export default class BGVis extends Component {

    constructor(props) {
        super(props);
        this.state = {             
            //Screen Size
            width: 0,
            height: 0
        };

    //Update Screen Size on change
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    render() {
      return (        
        //height var to be updated
        <Surface width={this.state.width} height={this.state.height*0.95}>
          <Node shader={shaderObject[this.props.currentShader]}
                uniforms={{
                  timer: this.props.timer,
                  bass: this.props.bass,
                  mid: this.props.mid,
                  hi: this.props.hi,
                  randommult: this.props.randommult,
                }} />
         </Surface>
    );
  }

  //Check for window resize
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
}
