import React, { Component } from 'react';
import Slider from "react-slick";
import { featuresContentExports } from '../Site-Content/featuresContentArray';
import { mixContentExports } from '../Site-Content/mixContentArray';

export class MobileFeatureDetails extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
    }
  }
  render() {
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div id={'mobilefeaturelisting' + this.props.featureid} className='MobileFeatureContentListing' featureid={this.props.featureid}>
        {/* !!! ADD IMAGE Carousel !!! */}
        {featuresContentExports[this.props.featureid].featurevideo01
          ? <video id={'featureImage'+this.props.featureid} featureid={this.props.featureid} width='100%' height='auto' src={featuresContentExports[this.props.featureid].featurevideo01.default } controls />
          : <img id={'featureImage'+this.props.featureid} featureid={this.props.featureid} width='100%' height='auto' src={featuresContentExports[this.props.featureid].image01large.default} />
        }
        <div className='MobileTitle' id={'featureTitle'+this.props.featureid} featureid={this.props.featureid}> {featuresContentExports[this.props.featureid].title} </div>
        <div className='MobileFeatureContentListingBody' id={'mobilefeaturelistingbody'+this.props.featureid} featureid={this.props.featureid}>
          <div className='.MobileContentBodyText' id={'mobileFeatureBodyText'+this.props.featureid} />
        </div>    
             
      </div>
    )
  }
  componentDidMount() {
    let mobileContentListing = document.getElementById('mobilefeaturelisting' + this.props.featureid);
    mobileContentListing.style.order = mixContentExports.length + featuresContentExports.length - (featuresContentExports[this.props.featureid].order - 1);
    document.getElementById('featureImage' + this.props.featureid).addEventListener('click', this.props.mobileContentExtend.bind(this));
    document.getElementById('featureTitle' + this.props.featureid).addEventListener('click', this.props.mobileContentExtend.bind(this));
    document.getElementById('mobileFeatureBodyText'+this.props.featureid).innerHTML = featuresContentExports[this.props.featureid].bodytext;
  }
  componentWillUnmount() {
    //document.getElementById('mixImage'+this.props.mixid).removeEventListener('click', this.props.mobileContentExtend.bind(this));
    //document.getElementById('mixTitle'+this.props.mixid).removeEventListener('click', this.props.mobileContentExtend.bind(this));
    //document.getElementById('mobileartistlink'+this.props.mixid).removeEventListener('click', () => { window.location = mixContentExports[this.props.mixid].artistlink; }, false);
  }
}

export default MobileFeatureDetails
