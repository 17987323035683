import React, { Component } from 'react';
import PropTypes from "prop-types";
import {mixContentExports} from '../Site-Content/mixContentArray';
import '../Stylesheets/App.css';
import { shaders } from './bgVis';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";

export class DesktopMixList extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props)
    
    this.state = {
      nextshader: 1,
    }
    this.mixContentSelect = this.mixContentSelect.bind(this);
  }
    render() {
          const { match, location, history } = this.props;
          return (            
            <div id="mixwindowwrapper">      
                <div className="MixWindow" id="mwtopleft">
                    <button class="MixTitleButton"> MIXES </button>
                </div>
                <div className="MixWindow" id="mixlistgif">        
                        <img src={require("../Site-Content/Icons/GIF4.gif").default} alt="trish logo animated gif" height='100%' />           
                </div>
                <div className="MixWindow" id="mwbottomleft">
                  <div id="mixlistcontainer" />
                </div>       
          </div>
        )        
    }
    
    componentDidMount() {
      this.initMixList();  
      let trishLogo = document.getElementById('trishlogo');
      trishLogo.style.width = '8vw';
      trishLogo.addEventListener('click', () => this.goHome());
    }
    componentWillUnmount() {
      let trishLogo = document.getElementById('trishlogo');
      trishLogo.style.width = '12vw';
      trishLogo.removeEventListener('click', () => this.goHome());
    }
    //load desktop mix list
    initMixList(){
      let mixImageWindow = document.getElementById('miximagewindow');
      if (mixImageWindow != null){ mixImageWindow.remove(); }
      let listContainer = document.getElementById('mixlistcontainer');
      let genmixlist = document.createElement('ul');
      genmixlist.setAttribute('id', 'mixlist')
      let numberOfListItems = mixContentExports.length,
      listItem,
      i;
      listContainer.appendChild(genmixlist);
      for (i=numberOfListItems-1; i>=0; i--) {
        listItem = document.createElement('li');
        let artist = mixContentExports[i].artist;
        this.setAttributes(listItem, {'class': 'MixListButton', 'mixid': i });
        listItem.addEventListener("click", this.mixContentSelect.bind(this));
        listItem.innerHTML = artist;
        genmixlist.appendChild(listItem);
      }
    }
    //Open Individual Desktop Mix Details
    mixContentSelect(e){
      let mixid = e.target.getAttribute('mixid');
      this.props.changeMixId(mixid);
      //set new url
      let mixURLExt = mixContentExports[mixid].artist.replace(/\s/g, '-');
      this.props.history.push('/mixes/' + mixURLExt);
    }   
    goHome(){
      this.props.history.push('/');      
    }
  //HELPER FUNCTIONS
  setAttributes(el, attrs) {
    for(var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }
}

export default withRouter(DesktopMixList)
