import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, withRouter } from 'react-router-dom';
import '../Stylesheets/App.css';

export class BottomBarMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
          scrollDuration: 300
        }
    }
    render() {
        return (
            <div className="BottomBarMenu">                                    
                    <Route exact path="/">
                        <Link to='/mixes' class="BarLink">
                            <button class="BarButton" id="mixbutton"> MIXES </button>
                        </Link>
                        <Link to="/features" class="BarLink">
                            <button class="BarButton" id="featurebutton"> FEATURES </button>
                        </Link>
                        <Link to="/virtual" class="BarLink">
                            <button class="BarButton" id="virtualbutton" onClick={() => this.props.stopTimer()}>
                                <div id="rainbowtext"> VIRTUAL </div>
                            </button>
                        </Link>
                        <Link to="/shop" class="BarLink">
                            <button class="BarButton" onClick={() => this.shopWindow()}> SHOP </button>
                        </Link> 
                    </Route>
                    {/*Mixes Bottom Bar Menu*/}
                    <Route exact path="/mixes">
                        <Link to='/' class="BarLink">
                            <button class="BarButton" id="mixbutton"> MIXES </button>
                        </Link>
                        <Link to="/features" class="BarLink">
                            <button class="BarButton" id="featurebutton"> FEATURES </button>
                        </Link>
                        <Link to="/virtual" class="BarLink">
                            <button class="BarButton" id="virtualbutton" onClick={() => this.props.stopTimer()}>
                                <div id="rainbowtext"> VIRTUAL </div>
                            </button>
                        </Link>
                        <Link to="/shop" class="BarLink">
                            <button class="BarButton" onClick={() => this.shopWindow()}> SHOP </button>
                        </Link> 
                    </Route>
                    <Route path="/mixes/:id">
                      <Link to='/mixes' class="BarLink">
                        <button class="BarButton" id="mixbutton"> MIXES </button>
                      </Link>
                      <Link to="/features" class="BarLink">
                        <button class="BarButton" id="featurebutton"> FEATURES </button>
                      </Link>
                      <Link to="/virtual" class="BarLink">
                        <button class="BarButton" id="virtualbutton" onClick={() => this.props.stopTimer()}>
                          <div id="rainbowtext"> VIRTUAL </div>
                        </button>
                      </Link>
                      <Link to="/shop" class="BarLink">
                        <button class="BarButton" onClick={() => this.shopWindow()}> SHOP </button>
                      </Link>
                    </Route>
                    {/*Features Bottom Bar Menu*/}
                    <Route path="/features">
                        <Link to='/mixes' class="BarLink">
                            <button class="BarButton" id="mixbutton"> MIXES </button>
                        </Link>
                        <Link to="/" class="BarLink">
                            <button class="BarButton" id="featurebutton"> FEATURES </button>
                        </Link>
                        <Link to="/virtual" class="BarLink">
                            <button class="BarButton" id="virtualbutton" onClick={() => this.props.stopTimer()}>
                                <div id="rainbowtext"> VIRTUAL </div>
                            </button>
                        </Link>
                        <Link to="/shop" class="BarLink">
                            <button class="BarButton" onClick={() => this.shopWindow()}> SHOP </button>
                        </Link> 
                    </Route>
                    {/*Virtual Home Bottom Bar Menu*/}
                    <Route path="/virtual">
                        <Link to='/mixes' class="BarLink">
                            <button class="BarButton" id="mixbutton" onClick={() => this.props.resetTimer()}> MIXES </button>
                        </Link>
                        <Link to="/features" class="BarLink">
                            <button class="BarButton" id="featurebutton" onClick={() => this.props.resetTimer()}> FEATURES </button>
                        </Link>
                        <Link to="/" class="BarLink">
                            <button class="BarButton" id="virtualbutton" onClick={() => this.props.resetTimer()}>
                                <div id="rainbowtext"> VIRTUAL </div>
                            </button>
                        </Link>
                        <Link to="/shop" class="BarLink">
                            <button class="BarButton" onClick={() => this.shopWindow()}> SHOP </button>
                        </Link> 
                        </Route>
                    {/*Virtual Home Bottom Bar Menu*/}
                    <Route path="/shop">
                        <Link to='/mixes' class="BarLink">
                            <button class="BarButton" id="mixbutton" onClick={() => this.props.resetTimer()}> MIXES </button>
                        </Link>
                        <Link to="/features" class="BarLink">
                            <button class="BarButton" id="featurebutton" onClick={() => this.props.resetTimer()}> FEATURES </button>
                        </Link>
                        <Link to="/virtual" class="BarLink">
                            <button class="BarButton" id="virtualbutton" onClick={() => this.props.resetTimer()}>
                                <div id="rainbowtext"> VIRTUAL </div>
                            </button>
                        </Link>
                        <Link to="/shop" class="BarLink">
                            <button class="BarButton" onClick={() => this.shopWindow()}> SHOP </button>
                        </Link> 
                    </Route>             
                    {/*Mobile Home Bar Menu*/}                 
                    <Route path="/m/">
                        <Link to='/m/mixes/' class="BarLink">
                            <button class="BarButton" id="mixbutton" onClick={() => this.mobileMixesList()}> MIXES </button>
                        </Link>
                        <Link to="/m/features/" class="BarLink">
                            <button class="BarButton" id="featurebutton" onClick={() => this.mobileFeaturesList()}> FEATURES </button>
                        </Link>
                        <Link to="/m/shop" class="BarLink">
                            <button class="BarButton" onClick={() => this.shopWindow()}> SHOP </button>
                        </Link> 
                    </Route>                 
            </div>
        )
    }
    componentDidMount(){
      if (this.props.mobileRule){
        
        let mobileVersion = this.props.location.pathname;
        if (!mobileVersion.includes('/m/')){
          let newAddress = '/m';
          newAddress = newAddress.concat(mobileVersion)
          this.props.history.push(newAddress);
          window.location.reload();
        }
      } else {
        let desktopVersion = this.props.location.pathname;
        if (desktopVersion.includes('/m/')){
          let newAddress = desktopVersion.replace(/\/m\//g, '/');
          this.props.history.push(newAddress);
          window.location.reload();
        }
        
      }
    }
    componentWillUnmount(){

    }
    //Open Shop Link
    shopWindow(){
      window.location = 'https://clubtrish.bigcartel.com/';
    }
    //Mobile Fake Navigation
    mobileMixesList(){
      //if details open, close them
      let activeListings = document.getElementsByClassName('MobileMixContentListing--active');
      let numberOfActiveListItems = activeListings.length, l;
      for (l=numberOfActiveListItems-1; l>=0; l--) { activeListings[l].style.maxHeight = 0; activeListings[l].classList.remove('MobileMixContentListing--active'); }
      let mobileHiddenList = document.getElementsByClassName('HidenMobileContentListing');
      let numberOfHidenListItems = mobileHiddenList.length, i;
      for (i=numberOfHidenListItems-1; i>=0; i--) { mobileHiddenList[i].classList.remove('HidenMobileContentListing'); }
      let mobileFeatureList = document.getElementsByClassName('MobileFeatureContentListing');
      let numberOfFeatureListItems = mobileFeatureList.length, k;
      for (k=numberOfFeatureListItems-1; k>=0; k--) { mobileFeatureList[k].classList.add('HidenMobileContentListing'); }
      let scrollTarget = document.getElementById('mobileheadergif').getBoundingClientRect().height;
      this.scrollTo(scrollTarget, this.state.scrollDuration);
    }
    //Open Mobile Feature List
    mobileFeaturesList(){
      //if details open, close them
      let activeListings = document.getElementsByClassName('MobileMixContentListing--active');
      let numberOfActiveListItems = activeListings.length, l;
      for (l=numberOfActiveListItems-1; l>=0; l--) { activeListings[l].style.maxHeight = 0; activeListings[l].classList.remove('MobileMixContentListing--active'); }
      let mobileHiddenList = document.getElementsByClassName('HidenMobileContentListing');
      let numberOfHidenListItems = mobileHiddenList.length, i;
      for (i=numberOfHidenListItems-1; i>=0; i--) { mobileHiddenList[i].classList.remove('HidenMobileContentListing'); }
      let mobileMixList = document.getElementsByClassName('MobileMixContentListing');
      let numberOfMixListItems = mobileMixList.length, k;
      for (k=numberOfMixListItems-1; k>=0; k--) { mobileMixList[k].classList.add('HidenMobileContentListing'); }
      let scrollTarget = document.getElementById('mobileheadergif').getBoundingClientRect().height;
      this.scrollTo(scrollTarget, this.state.scrollDuration);
    }
    //Open Mobile Feature List
    mobileHomeList(){
      //if details open, close them
      let activeListings = document.getElementsByClassName('MobileMixContentListing--active');
      let numberOfActiveListItems = activeListings.length, l;
      for (l=numberOfActiveListItems-1; l>=0; l--) { activeListings[l].style.maxHeight = 0; activeListings[l].classList.remove('MobileMixContentListing--active'); }
      let mobileHiddenList = document.getElementsByClassName('HidenMobileContentListing');
      let numberOfHidenListItems = mobileHiddenList.length, i;
      for (i=numberOfHidenListItems-1; i>=0; i--) { mobileHiddenList[i].classList.remove('HidenMobileContentListing'); }
      this.scrollTo(0, this.state.scrollDuration);
    }

    scrollTo (target, duration) {
      let scrollingElement = document.getElementById('mobilecontentlist');
      let scrollFrom = scrollingElement.scrollTop;
      if (scrollingElement.scrollTop === target) return;      
      if(target < scrollFrom){
      //if scrolling down the page
      const cosParameter = (target - scrollFrom) / 2;
      let scrollCount = 0, oldTimestamp = null;  
      function step (newTimestamp) {
          if (oldTimestamp !== null) {
              // if duration is 0 scrollCount will be Infinity
              scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
              //console.log(scrollCount)
              if (scrollCount >= Math.PI) return scrollingElement.scrollTop = target;
              scrollingElement.scrollTop = scrollFrom + (cosParameter + cosParameter * Math.cos(Math.PI - scrollCount));
          }
          oldTimestamp = newTimestamp;
          window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
      } else {
      //if scrolling up the page
      const cosParameter = (scrollFrom - target) / 2;
      let scrollCount = 0, oldTimestamp = null;  
      function step (newTimestamp) {
          if (oldTimestamp !== null) {
              // if duration is 0 scrollCount will be Infinity
              scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
              //console.log(scrollCount)
              if (scrollCount >= Math.PI) return scrollingElement.scrollTop = target;
              scrollingElement.scrollTop = scrollFrom - (cosParameter + cosParameter * Math.cos(Math.PI - scrollCount));
          }
          oldTimestamp = newTimestamp;
          window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
      }      
    }
}

export default withRouter(BottomBarMenu)
