// To convert images to correct sizing from a 1920x1080 original place image file in Site-Content/Mix-Images/Mix-Image-Conversion and run ' npm run sharpmix ' from cmd line
// Cut and paste newly generated images to /Features-Images

//!! Don't forget to check order when adding new content

// if artist name contains a dash like this '-' it won't work with the site, please let me know if this comes up and i can change it to something else, it just looks nice in the url

export const mixContentExports = [
  {"number":"001",
   "artist":"Chri_ssss",
   "description":"The creator of Trish leads you on a musical journey of Brazil, from the river banks of the Amazon in the northern state of Pará down to the thriving metropolis of São Paulo, inspired by his time living in Brazil, Chri_ssss, a.k.a Trish brings you this mix, with love. Esse mix é dedicado aos meus queridos amigos do Brasil.",
   "order": "2",
   "imagelarge": require("./Mix-Images/Mix-Image-001-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-001-small.jpg"),
   "mixname": "TrishCast 001 - Chri_ssss", 
   "mixfile": require("./Mix-Audio/TRISHMIX001.mp3"), 
   "artistlink": "https://soundcloud.com/chrisssnicholsss",
  },

  {"number":"002",
   "artist":"Nick Ling",
   "description":"Run a hot shower. Lather, slowly.",
   "order": "3",
   "imagelarge": require("./Mix-Images/Mix-Image-002-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-002-small.jpg"),
   "mixname": "TrishCast 002 - Nick Ling", 
   "mixfile": require("./Mix-Audio/TRISHMIX002.mp3"), 
   "artistlink": "https://soundcloud.com/",
  },

  {"number":"003",
   "artist":"Kauan Marco",
   "description":"EN: The Brazilian Kauan Marco, who just launched his second album \“Escola da Rua\” is an artist from the charming Rio de Janeiro record label 40%Foda/maneiríssimo, the DJ, musical producer and pianist, presents us with a mix blending slow, and smooth sounds with swing and sensuality. <p /> PT: O brasileiro Kauan Marco, que acaba de lançar seu segundo álbum \"Escola da Rua\", é artista do charmosíssimo label do Rio de Janeiro 40%Foda/maneiríssimo, o DJ, produtor musical e pianista nos presenteia com um mix lento, malandro, com swing e muita sensualidade.,",
   "order": "4",
   "imagelarge": require("./Mix-Images/Mix-Image-003-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-003-small.jpg"),
   "mixname": "TrishCast 003 - Kauan Marco", 
   "mixfile": require("./Mix-Audio/TRISHMIX003.mp3"), 
   "artistlink": "https://soundcloud.com/kauanmarcomusic",
  },

  {"number":"004",
   "artist":"Louis Avolo",
   "description":"Louis Avolo is a Melbourne based artist creating contemporary electronic music for an imaginary dance floor and private listening. When not creating music he is enjoying getting the neighbourhood gossip from his Nonna and learning her classic recipes.",
   "order": "5",
   "imagelarge": require("./Mix-Images/Mix-Image-004-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-004-small.jpg"),
   "mixname": "TrishCast 004 - Louis Avolo", 
   "mixfile": require("./Mix-Audio/TRISHMIX004.mp3"), 
   "artistlink": "https://soundcloud.com/louis-avolo",
  },

  {"number":"005",
   "artist":"Char Mama",
   "description":"I imagine this music vibrating from the souls of my feet as I bop along at my decks, through the Earth mother and entering you from your ground. It travels up through your body and out through your crown. Together we are bound in pulsing percussions, deep melodic beats and harmonies. This mix is light and energetic as the Spring from which it burst. Have fun!",
   "order": "6",
   "imagelarge": require("./Mix-Images/Mix-Image-005-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-005-small.jpg"),
   "mixname": "TrishCast 005 - Char Mama", 
   "mixfile": require("./Mix-Audio/TRISHMIX005.mp3"), 
   "artistlink": "https://soundcloud.com/char_mama",
  },

  {"number":"006",
   "artist":"Julius Jenseits",
   "description":"The Germany based Julius Jenseits brings you a mixture of playful and ethereal sounds driven by deep bass made for the dancefloor. His set for TRISH is also his first digitally live streamed piece. Enjoy!",
   "order": "7",
   "imagelarge": require("./Mix-Images/Mix-Image-006-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-006-small.jpg"),
   "mixname": "TrishCast 006 - Julian Jenseits", 
   "mixfile": require("./Mix-Audio/TRISHMIX006.mp3"), 
   "artistlink": "www.soundcloud.com/julius_jenseits",
  },

  {"number":"007",
   "artist":"Gibras",
   "description":"George Floyd unleashes several protests around the world; Afro brazilians suffer from police repression on favelas as well as aboriginals on suburbs of Australia. Both countries watched their forests burn, threatening most of the native people. This pandemic year was harder for those who don't have a bank account and not even water to wash their hands to protect themselves. We saw more clearly historical and systemic social problems coming up. <p /> Gibras is a white straight guy from the south of Brazil, the most fascist region of the country; now living in São Paulo trying to decolonize his mind with the help of black music and always looking for the future/contemporary indigenous artists that are growing more and more in America and Oceania. \"Nuestro norte es el sur\" - Joaquín Torres García Gerson King Combo, in memorian (1943-2020)",
   "order": "8",
   "imagelarge": require("./Mix-Images/Mix-Image-007-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-007-small.jpg"),
   "mixname": "TrishCast 007 - Gibras", 
   "mixfile": require("./Mix-Audio/TRISHMIX007.mp3"), 
   "artistlink": "www.soundcloud.com/gibranst",
  },

  {"number":"008",
   "artist":"Kovac & O’Bryan",
   "description":"This mix was born at Club Trish in August 2019. We were playing a bsb set and everything fell into place, guided by the vibes of the crowd and the immersive lighting programming. We recorded it on the night, but selected the wrong input, so all we got was the room sound and thumping subs. Re-recorded in lockdown, we hope this mix captures some of the vibes of the party that night.",
   "order": "9",
   "imagelarge": require("./Mix-Images/Mix-Image-008-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-008-small.jpg"),
   "mixname": "TrishCast 008 – Kovac & O’Bryan", 
   "mixfile": require("./Mix-Audio/TRISHMIX008.mp3"), 
   "artistlink": "https://soundcloud.com/the-rainbow-connection/",
  },


{"number":"009",
   "artist":"Carlim",
   "description":"Fernanda Carlim from the subtropical metropolis of São Paulo, Brazil has graced Club Trish with this sensual upbeat mix traversing the globe through the sounds of disco, rnb and house.. just in time for the heat of the Southern Hemipshere Summer &#127847",
   "order": "10",
   "imagelarge": require("./Mix-Images/Mix-Image-009-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-009-small.jpg"),
   "mixname": "TrishCast 009 – Carlim", 
   "mixfile": require("./Mix-Audio/TRISHMIX009.mp3"), 
   "artistlink": "https://soundcloud.com/fcrlm",
  },

{"number":"010",
   "artist":"A.K.A. Linda Green",
   "description":"To commemorate the ending of this tumultuous year, São Paulo dj and producer A.K.A. Linda Green has gifted us with this spicy mix flowing through genres and rhythms. <p />  Dj and producer A.K.A. Linda Green is known for her performances at the well renowned festivals including DGTL, Voodoohop, Xama and the Mamba Negra, O/NDA, Blum and Sonido Tropico parties in Brazil.",
   "order": "11",
   "imagelarge": require("./Mix-Images/Mix-Image-010-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-010-small.jpg"),
   "mixname": "TrishCast 010 – A.K.A. Linda Green", 
   "mixfile": require("./Mix-Audio/TRISHMIX010.mp3"), 
   "artistlink": "https://soundcloud.com/cecilindgren",
  },

  {"number":"011",
   "artist":"Giu Nunez",
   "description":"I called this mix BRAZITA: Sediments of Brazilia and Italian disco, from 78 to 88. Reworkings of Michael Jackson classics and also a Marco Buscema edit for the Italian version of Bill Withers' Ain’t No Sunshine.<p /> Other than that, you have to discover what is Italo and what is Brazilian, quiz yourself, what is \"Italo or Brazuka?\" ",
   "order": "12",
   "imagelarge": require("./Mix-Images/Mix-Image-011-large.jpg"),
   "imagesmall": require("./Mix-Images/Mix-Image-011-small.jpg"),
   "mixname": "TrishCast 011 – Giu Nunez", 
   "mixfile": require("./Mix-Audio/TRISHMIX011.mp3"), 
   "artistlink": "https://soundcloud.com/giununez",
  },

  {"number":"012",
  "artist":"Slowlife",
  "description":" \"I tried to make a work that was not focused on one part with my own color. So you can enjoy various things from disco to African music and house. I wish I could share my feelings with you through this mix. Thanks to the invitation of the club trish, I was able to create an interesting work and thank you again.\" ",
  "order": "13",
  "imagelarge": require("./Mix-Images/Mix-Image-012-large.jpg"),
  "imagesmall": require("./Mix-Images/Mix-Image-012-small.jpg"),
  "mixname": "TrishCast 012 – Slowlife", 
  "mixfile": require("./Mix-Audio/TRISHMIX012.mp3"), 
  "artistlink": "https://soundcloud.com/slowlife053",
 },

 {"number":"013",
 "artist":"Genki Tanaka",
 "description":" \"I recorded this mix during the second lockdown in Melbourne, so I had relistened to my collection and chosen B-side tracks from them. And also, I started to dig records recently and then I discovered two Japanese artists; Takayuki Shiraishi and Koji Ono. I did not expect to discover Japanese artists in Melbourne, I think music connected me to them. Hope you enjoy listening, Arigatooo!\" <p /> \" 新しいmixがメルボルンで活動するClub Trishからリリースされました。今回は先日のロックダウンでゆったり音楽を聞く機会ができたので、各作品のB面のトラックを聞き直し、ピックアップしています。また今年からレコードでのプレイを始めたのですが、レコ屋でTakyuki Shiraishi, Koji Onoの2人の日本人アーティストの楽曲に出会う事ができました。メルボルンで日本人アーティストを知るのはなんだか不思議な感覚ですね。ゆったり、たっぷり、のーんびりしたい時にぜひ聞いてみてくださいませ。どうぞおなしゃす。 \" ",
 "order": "14",
 "imagelarge": require("./Mix-Images/Mix-Image-013-large.jpg"),
 "imagesmall": require("./Mix-Images/Mix-Image-013-small.jpg"),
 "mixname": "TrishCast 013 – Genki Tanaka", 
 "mixfile": require("./Mix-Audio/TRISHMIX013.mp3"), 
 "artistlink": "https://soundcloud.com/genkitanaka",
},

{"number":"014",
"artist":"Gabi Bahia",
"description":" We have again been graced by another highly talented Brazilian Selector Gabi Bahia with her high energy club mix direct from São Paulo, Brazil. <p /> Gabi Bahia, the Minas Gerais native based in São Paulo, is a DJ, producer, sound technician and music curator since 2016 who fell in love with electronic music after a season in Paris. <p /> Involved in several cultural projects on the São Paulo scene, Gabi was one of the creators of Rádio Sal, on radio Veneno Live, the first weekly program exclusively dedicated to artists and female DJs and which received names such as DJ Bloody Mary, Badsista, Andrea Gram, among others. <p /> Her musical tastes are heavily influenced by dance music of the 80s. Her sets bring striking beats and groovy elements from house and disco, which have been played in the well known Brazilian dancefloors including Club Jerome, Heavy House, Selina, Comuna and parties like Gang, 1010 (BH) and MonkeyBuzz with Ross from Friends. <p /> Today, in addition to curating the platform Veneno Live, she is also a producer of the collective Elas Que Lutem, a creative platform to promote female talents in electronic music.",
"order": "15",
"imagelarge": require("./Mix-Images/Mix-Image-014-large.jpg"),
"imagesmall": require("./Mix-Images/Mix-Image-014-small.jpg"),
"mixname": "TrishCast 014 – Gabi Bahia", 
"mixfile": require("./Mix-Audio/TRISHMIX014.mp3"), 
"artistlink": "https://soundcloud.com/gabriela-bahia",
},

{"number":"015",
"artist":"Berkay Mete",
"description":"Fresh out of the oven, a very much anticipated mix by dear friend of Trish Berkay Mete. <p /> Berkay brings sounds and musical traditionals from his Anatolian roots to a new land, his music makes you want to sway from side to side, eyes locked with a significant other, immersed in pulsating pink and red light moving and surrounded by an undulating haze. It’s dreamy, smooth and funky. It is a very special mix featuring unreleased original pieces by Berkay which we are very proud to share with the world. <p /> Next month Berkay will be launching Kulampara records, an Australian first which will be releasing electronica sounds inspired by the Middle East and Anatolia. <p /> We hope you enjoy this mix, one very much loved by Club Trish <p /> x.",
"order": "16",
"imagelarge": require("./Mix-Images/Mix-Image-015-large.jpg"),
"imagesmall": require("./Mix-Images/Mix-Image-015-small.jpg"),
"mixname": "TrishCast 015 – Berkay Mete", 
"mixfile": require("./Mix-Audio/TRISHMIX015.mp3"), 
"artistlink": "https://soundcloud.com/berkaymete",
},

{"number":"016",
"artist":"Salvador",
"description":"Another lockdown, another mix ~ Salvador Ricardo a.k.a. General Purpose takes us on a rhythmic journey with his new mix for Club Trish. <p />  Textural, flavourful, pulsating..enjoy!.",
"order": "17",
"imagelarge": require("./Mix-Images/Mix-Image-016-large.jpg"),
"imagesmall": require("./Mix-Images/Mix-Image-016-small.jpg"),
"mixname": "TrishCast 016 – Salvador", 
"mixfile": require("./Mix-Audio/TRISHMIX016.mp3"), 
"artistlink": "https://soundcloud.com/salvadorricardo",
},

{"number":"017",
"artist":"Chri_ssss (return)",
"description":"To commemorate a year since the rebirth of this project, the chief Trish returns to bring you an upbeat juicy mix with flavours of Lychee, Calamansi and Caju. <p /> Featuring sounds from South Africa, early 90s EU, Brazil and the Phillipines, let Trish take you on a journey through sound.. <p /> Soon soon we will see eachother again.",
"order": "18",
"imagelarge": require("./Mix-Images/Mix-Image-017-large.jpg"),
"imagesmall": require("./Mix-Images/Mix-Image-017-small.jpg"),
"mixname": "TrishCast 017 - Chri_ssss", 
"mixfile": require("./Mix-Audio/TRISHMIX017.mp3"), 
"artistlink": "https://soundcloud.com/chri_ssss",
},

{"number":"018",
"artist":"DJ PULP FREE",
"description":" Presenting a new mix by Dj Pulp Free ~ a man who feels most at home in the wilderness ~ loves sabor and a disco beat ~ just in time for a new life opening up in front of our eyes, press play and shimmer into a colourful future.",
"order": "19",
"imagelarge": require("./Mix-Images/Mix-Image-018-large.jpg"),
"imagesmall": require("./Mix-Images/Mix-Image-018-small.jpg"),
"mixname": "TrishCast 018 - DJ PULP FREE", 
"mixfile": require("./Mix-Audio/TRISHMIX018.mp3"), 
"artistlink": "https://soundcloud.com/djpulpfree",
},

{"number":"019",
"artist":"Ani Phoebe",
"description":" To close out 2021 and usher in 2022 we are very excited to present the final mix of the year from Hong Kong based selector Ani Phoebe. <p /> It's spicy, cosmic and groovy with a latin twist. Ani draws on her multi-cultural and multi-lingual background in her sets, growing up in NYC, Rio De Janeiro & Hong Kong, she has always been attracted to the weird and oddball side of dance music. <p /> Ani is a resident at The Lot Radio (NY) where she runs a monthly show called Parallel Worlds, dedicated to diggers from different subcultures around the world. We hope you love this mix as much as we did and we wish you all a happy new year! 🍹🍹🍹",
"order": "20",
"imagelarge": require("./Mix-Images/Mix-Image-019-large.jpg"),
"imagesmall": require("./Mix-Images/Mix-Image-019-small.jpg"),
"mixname": "TrishCast 019 - Ani Phoebe", 
"mixfile": require("./Mix-Audio/TRISHMIX019.mp3"), 
"artistlink": "https://soundcloud.com/aniphoebe",
},

{"number":"020",
"artist":"Dj Niamh",
"description":" Finally we've landed and return to present an anticipated mix as our first of 2022 by one of our favourite Melbourne based djs DJ Niamh. <p /> This live set was played in the Trades Hall Ballroom at last December's Club Trish. <p /> Be transported back to the fruity haze with this dreamy set, which took us on a journey through disco, new-beat, break, bass and just bliss.",
"order": "21",
"imagelarge": require("./Mix-Images/Mix-Image-020-large.jpg"),
"imagesmall": require("./Mix-Images/Mix-Image-020-small.jpg"),
"mixname": "TrishCast 020 - DJ Niamh", 
"mixfile": require("./Mix-Audio/TRISHMIX020.mp3"), 
"artistlink": "https://soundcloud.com/evelkniamhel",
},
  
]