import React, { Component } from 'react';
import {mixContentExports} from '../Site-Content/mixContentArray';
import {featuresContentExports} from '../Site-Content/featuresContentArray';
import { BrowserRouter as Router, Route, Link, Switch, withRouter } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MobileMixDetails from './mobileMixDetails';
import MobileFeatureDetails from './mobileFeatureDetails';

export class MobileList extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      scrollDuration: 300
    }
  }
  render() {
    return (
      
      <div id="mobilewrapper">
        <Route path="/m/mixes/">
          
        </Route>
        <Route path="/m/features/">

        </Route>
        <Route path="/m/">

        </Route>
        <div id="mobilecontentlist">
          <div id="mobileheadergif">
            <img src={require("../Site-Content/Icons/GIF4.gif").default} width="100%" height="auto" />
          </div>
          {Array.from(Array(mixContentExports.length)).map((x, index) => <MobileMixDetails mixid={index} mobileContentExtend={this.mobileMixExtend.bind(this)} setAttributes={this.setAttributes.bind(this)} changeMix={this.props.changeMix.bind(this)}/>)}
          {Array.from(Array(featuresContentExports.length)).map((x, index) => <MobileFeatureDetails featureid={index} mobileContentExtend={this.mobileFeatureExtend.bind(this)} />)}
        </div>

      </div>
    )
  }
  componentDidMount() {
    document.getElementById('desktopwrapper').remove();
    //this.initMobList();
    this.setState({mixEntries: mixContentExports.length, featureEntries: featuresContentExports.length})
  }
  componentWillUnmount() {

  }

  //Open Individual Mobile Article Details
  mobileMixExtend(e){     
    let mixid = e.target.getAttribute('mixid');
    let mobileContentListing = document.getElementById('mobilemixlisting' + mixid);
    let mobileContentListingBody = document.getElementById('mobilemixlistingbody' + mixid);
    let style = mobileContentListing.currentStyle || window.getComputedStyle(mobileContentListing);
    let scrollTarget = document.getElementById('mobilemixlisting' + mixid).offsetTop - (parseInt(style.marginBottom)/2);
    this.scrollTo(scrollTarget, this.state.scrollDuration);
    mobileContentListingBody.classList.toggle('MobileMixContentListing--active');
    if (mobileContentListingBody.classList.contains('MobileMixContentListing--active')){      
      mobileContentListingBody.style.maxHeight = mobileContentListingBody.scrollHeight + 'px';      
    } else {
      mobileContentListingBody.style.maxHeight = 0;
    }
  }
  //Open Individual Mobile Article Details
  mobileFeatureExtend(e){     
    let featureid = e.target.getAttribute('featureid');
    let mobileContentListing = document.getElementById('mobilefeaturelisting' + featureid);
    let mobileContentListingBody = document.getElementById('mobilefeaturelistingbody' + featureid);
    let style = mobileContentListing.currentStyle || window.getComputedStyle(mobileContentListing);
    let scrollTarget = document.getElementById('mobilefeaturelisting' + featureid).offsetTop - (parseInt(style.marginBottom)/2);
    this.scrollTo(scrollTarget, this.state.scrollDuration);
    mobileContentListingBody.classList.toggle('MobileFeatureContentListing--active');
    if (mobileContentListingBody.classList.contains('MobileFeatureContentListing--active')){      
      mobileContentListingBody.style.maxHeight = mobileContentListingBody.scrollHeight + 'px';      
    } else {
      mobileContentListingBody.style.maxHeight = 0;
    }
    let mobileMixList = document.getElementsByClassName('MobileFeatureContentListing');
    //console.log(mobileMixList.map((o) => o.order).indexOf(featuresContentExports[]))
    //let numberOfMixListItems = mobileMixList.length, k;
    //for (k=numberOfMixListItems-1; k>=0; k--) { mobileMixList[k].classList.add('HidenMobileContentListing'); }
  }

  
  
  //HELPER FUNCTIONS
  setAttributes(el, attrs) {
    for(var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }
  scrollTo (target, duration) {
    let scrollingElement = document.getElementById('mobilecontentlist');
    let scrollFrom = scrollingElement.scrollTop;
    if (scrollingElement.scrollTop === target) return;      
    if(target < scrollFrom){
    //if scrolling down the page
    const cosParameter = (target - scrollFrom) / 2;
    let scrollCount = 0, oldTimestamp = null;  
    function step (newTimestamp) {
        if (oldTimestamp !== null) {
            // if duration is 0 scrollCount will be Infinity
            scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
            //console.log(scrollCount)
            if (scrollCount >= Math.PI) return scrollingElement.scrollTop = target;
            scrollingElement.scrollTop = scrollFrom + (cosParameter + cosParameter * Math.cos(Math.PI - scrollCount));
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
    } else {
    //if scrolling up the page
    const cosParameter = (scrollFrom - target) / 2;
    let scrollCount = 0, oldTimestamp = null;  
    function step (newTimestamp) {
        if (oldTimestamp !== null) {
            // if duration is 0 scrollCount will be Infinity
            scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
            //console.log(scrollCount)
            if (scrollCount >= Math.PI) return scrollingElement.scrollTop = target;
            scrollingElement.scrollTop = scrollFrom - (cosParameter + cosParameter * Math.cos(Math.PI - scrollCount));
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
    }      
  }
}

export default MobileList
