import React, { Component } from 'react';
import '../Stylesheets/App.css';
import {mixContentExports} from '../Site-Content/mixContentArray';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";

export class DesktopAudioPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //audio component
      sources: [ {src: mixContentExports[mixContentExports.length -1].mixfile.default, type: 'audio/mp3'}],
      nowPlayingName: mixContentExports[mixContentExports.length-1].mixname,
      currentAudioTime: 0.0,
      audioDuration: 0.0,
      currentVolume: 1.0,
      tempVolume: 1.0,
      muted: false,
      // current audio timeline width - needs to be adjusted on window resize
      timelineWidth: 0,
      
    }
  }
  render() {
    const { match, location, history } = this.props;
    return (
      <div className="AudioPlayerContainer"> 

        <div className="AudioPlayer" id="audioplayer"> 

          {/*Buttons*/}
          <button id="pButton" class="play" onClick={() => this.playAudio()} />
          <button id="rButton" class="reset" onClick={() => this.resetAudio()} />
          <button id="vButton" class="volume" onClick={() => this.muteAudio()} onMouseEnter={() => this.revealVolume()} onMouseLeave={() => this.hideVolume()} />
          <button id="mButton" class="mobilehome" onClick={() => this.mobileHome()} />

          {/*Volume*/}
          <div className="volumewrapper" onMouseEnter={() => this.revealVolume()} onMouseLeave={() => this.hideVolume()}>
            <div id="volumeslider">
              <div id="volumeindicator" />
            </div>
          </div>

          {/*Audio Timeline*/}
          <div id="timeline" class="timelineloaded">
            <div id="playhead" />
          </div>
        </div>

        {/*Scroll Text Overlay*/}
        <div className="AudioPlayerOverlay">
          <div class="marquee">
            <div id="marqueescroll" />
          </div>
        </div>  

      </div>
    )
  }

  componentDidMount() {
    //link the audio player references
    this.music = document.getElementById('audiotag');
    this.playhead = document.getElementById('playhead');
    this.timeline = document.getElementById('timeline');
    this.timelineWidth = this.timeline.offsetWidth;
    //if audio duration changes update state
    this.music.addEventListener('durationchange', (e) => {
      this.setState({
        audioDuration: this.music.duration,
        timelineWidth: this.timeline.offsetWidth
      }) });
    //if waiting for audio to load
    this.music.addEventListener('waiting', (e) => {
      this.timeline.className = "";
      this.timeline.className = "timelineloading";
    });
    //if audio now playing
    this.music.addEventListener('playing', (e) => {
      this.timeline.className = "";
      this.timeline.className = "timelineloaded";
    });
    //move the playhead as audio plays
    this.music.addEventListener('timeupdate', this.timeUpdate, false);
    //handle audio timeline click
    this.timeline.addEventListener('click', e => {
      this.movePlayhead(e);
      this.music.currentTime = this.state.audioDuration * this.clickPlayPercent(e);
    }, false);
    //handle volume setting click
    document.getElementById('volumeslider').addEventListener('click', e => {
      this.props.moveVolumeIndicator(e);
    }, false);
    //resize timeline
    window.addEventListener('resize', this.updateTimelineDimensions.bind(this));
  }
  updateTimelineDimensions(){
    this.setState({ timelineWidth: this.timeline.offsetWidth });
  }
  playAudio() {
    let pButton = document.getElementById('pButton');
    this.music = document.getElementById('audiotag');
    //init analyser if on none exists and on desktop
    if (this.music.paused) {
      if (!this.props.musicInit){
        this.props.initAudioAnalyser();
        this.props.triggerAnimation();
        this.props.nowPlayingScroll();
      }
      this.music.play();
      pButton.className = '';
      pButton.className = 'pause';
      //this.props.triggerAnimation();
    } else {
      this.music.pause();
      pButton.className = '';
      pButton.className = 'play';
    } 
  }
  muteAudio() {
    let pButton = document.getElementById('vButton');
    if (!this.props.musicInit){
      this.props.initAudioAnalyser();
      this.props.triggerAnimation();
      this.setState({muted: true}, function(){ this.props.changeVolume(0.0); })
      pButton.className = '';
      pButton.className = 'mute';      
    }
    else{
      if(!this.state.muted){
        console.log(this.props.currentVolume)
        this.setState({muted: true})
        pButton.className = '';
        pButton.className = 'mute';
        this.props.changeVolume(0.0);
      }
      else{      
        this.setState({muted: false})
        pButton.className = '';
        pButton.className = 'volume';
        this.props.changeVolume(this.props.currentVolume);
        }
    }      
  }
  //reset track to beginning
  resetAudio() { this.music.currentTime = 0; }
  //volume display
  revealVolume() {
    let volumeWrapper = document.getElementsByClassName('volumewrapper')[0];
    volumeWrapper.classList.add('volumeunwrapped');
    volumeWrapper.classList.remove('volumewrapper');
  }
  hideVolume() {
    if(!this.state.volHover){
      let volumeWrapper = document.getElementsByClassName('volumeunwrapped')[0];
      volumeWrapper.classList.add('volumewrapper');
      volumeWrapper.classList.remove('volumeunwrapped');
    }
  }
  //move audio player playhead when clicked
  movePlayhead(e) {
    var newMargLeft = (e.clientX*0.8 - this.getPlayPosition(this.timeline));
    // if click further left than timeline set playhead to timeline left
    if (newMargLeft < 0) { this.playhead.style.width = '0px'; }
    // if click further right than timeline set playhead to timeline right
    if (newMargLeft > this.state.timelineWidth) { this.playhead.style.marginLeft = this.width + 'px'; }
    //else move to clicked position
    if (newMargLeft >= 0 && newMargLeft <= this.playhead) { this.playhead.style.width = newMargLeft + 'px'; }
  }
  getPlayPosition(el) {
    return el.getBoundingClientRect().left;
  }
  clickPlayPercent(e) {
    return (e.clientX - this.getPlayPosition(this.timeline)) / this.state.timelineWidth;
  }
  //move audio player playhead when playing
  timeUpdate = e => {
    this.setState({ currentAudioTime: e.target.currentTime });
    let playPercent = 100 * (this.state.currentAudioTime / this.state.audioDuration);
    this.playhead.style.width = playPercent + '%';
  };
  mobileHome(){
    //if details open, close them
    let activeListings = document.getElementsByClassName('MobileMixContentListing--active');
    let numberOfActiveListItems = activeListings.length, l;
    for (l=numberOfActiveListItems-1; l>=0; l--) { activeListings[l].style.maxHeight = 0; activeListings[l].classList.remove('MobileMixContentListing--active'); }
    let mobileHiddenList = document.getElementsByClassName('HidenMobileContentListing');
    let numberOfHidenListItems = mobileHiddenList.length, i;
    for (i=numberOfHidenListItems-1; i>=0; i--) { mobileHiddenList[i].classList.remove('HidenMobileContentListing'); }
    this.scrollTo(0, 2000)
    this.props.history.push('/m/');
  }
  scrollTo (target, duration) {
      let scrollingElement = document.getElementById('mobilecontentlist');
      let scrollFrom = scrollingElement.scrollTop;
      if (scrollingElement.scrollTop === target) return;      
      if(target < scrollFrom){
      //if scrolling down the page
      const cosParameter = (target - scrollFrom) / 2;
      let scrollCount = 0, oldTimestamp = null;  
      function step (newTimestamp) {
          if (oldTimestamp !== null) {
              // if duration is 0 scrollCount will be Infinity
              scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
              //console.log(scrollCount)
              if (scrollCount >= Math.PI) return scrollingElement.scrollTop = target;
              scrollingElement.scrollTop = scrollFrom + (cosParameter + cosParameter * Math.cos(Math.PI - scrollCount));
          }
          oldTimestamp = newTimestamp;
          window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
      } else {
      //if scrolling up the page
      const cosParameter = (scrollFrom - target) / 2;
      let scrollCount = 0, oldTimestamp = null;  
      function step (newTimestamp) {
          if (oldTimestamp !== null) {
              // if duration is 0 scrollCount will be Infinity
              scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
              //console.log(scrollCount)
              if (scrollCount >= Math.PI) return scrollingElement.scrollTop = target;
              scrollingElement.scrollTop = scrollFrom - (cosParameter + cosParameter * Math.cos(Math.PI - scrollCount));
          }
          oldTimestamp = newTimestamp;
          window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
      }      
    }
}

export default withRouter(DesktopAudioPlayer)
