import React, { Component } from 'react';
import { TwitchPlayer } from 'react-twitch-embed';

export class DesktopTwitchPlayer extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div id="twitchstream">
            <TwitchPlayer channel="clubtrish" height="100%" width="100%" hideControls="false" />
      </div>
    )
  }
  
  componentDidMount() {
    document.getElementById('bgvis').style.display = 'none';
    document.getElementById('trishlogo').style.display = 'none';
    document.getElementsByClassName('AudioPlayerContainer')[0].style.display = 'none';
  }
  componentWillUnmount() {
    document.getElementById('bgvis').style.display = 'flex';
    document.getElementById('trishlogo').style.display = 'grid';
    document.getElementsByClassName('AudioPlayerContainer')[0].style.display = 'block';
  }
  
}

export default DesktopTwitchPlayer

//muted={this.state.streamMuted}
