import React, { Component } from 'react';
import {featuresContentExports} from '../Site-Content/featuresContentArray';
import '../Stylesheets/App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";

export class DesktopFeatures extends Component {
    render() {
        return (
            <div id="featureswindowwrapper">
            <div id="featureswindowtitle">
              <span id="featureswindowtitletext"> FEATURES </span>        
            </div>
            <div id="featureslist" />
            <div id="featurecontent">
              <div id="featurecontenttitle" />
              <div id="featurecontentauthor" />
              <div id="featurecontentdate" />
              <div id="featurecontentbodytext" />
            </div>
            <div id="featureimages" />
          </div>
        )
    }
    componentDidMount() {
      //if no list init
      if (document.getElementById('featuresarchivelist') === null){ this.initFeaturesList(); };
      // if not on features home page !! look for better url variation matching      
      if(this.props.location.pathname != '/features' && this.props.location.pathname != '/features/'){
        this.selectedFeatureInit();
      }
      else{ 
        this.defaultFeatureInit();
      }
      let trishLogo = document.getElementById('trishlogo');
      trishLogo.style.width = '8vw';
      trishLogo.addEventListener('click', () => this.goHome());
    }
    componentWillUnmount() {
      let trishLogo = document.getElementById('trishlogo');
      trishLogo.style.width = '12vw';
      trishLogo.removeEventListener('click', () => this.goHome());
    }
    defaultFeatureInit(){
      let featureImages = document.getElementById('featureimages');
      //set first feature title, date, body text & images
      let latestFeatureId = featuresContentExports.length -1;
      document.getElementById('featurecontenttitle').innerHTML = featuresContentExports[latestFeatureId].title;
      document.getElementById('featurecontentauthor').innerHTML = featuresContentExports[latestFeatureId].author;
      document.getElementById('featurecontentdate').innerHTML = featuresContentExports[latestFeatureId].date;
      document.getElementById('featurecontentbodytext').innerHTML = featuresContentExports[latestFeatureId].bodytext;
      featureImages.innerHTML = '';
      if (featuresContentExports[latestFeatureId].image01large){
        let firstFeatureImage = document.createElement('img');
        this.setAttributes(firstFeatureImage, {'src': featuresContentExports[latestFeatureId].image01large.default, 'class': 'FeaturesImage', 'width': '100%', 'height': 'auto', 'srcset': featuresContentExports[latestFeatureId].image01small.default + ' 384w, ' + featuresContentExports[latestFeatureId].image01medium.default + ' 1152w, ' + featuresContentExports[latestFeatureId].image01large.default + ' 1920w', 'sizes': '23.333vw'});
        featureImages.appendChild(firstFeatureImage);
      }
      if (featuresContentExports[latestFeatureId].image02large){
        let secondFeatureImage = document.createElement('img');
        this.setAttributes(secondFeatureImage, {'src': featuresContentExports[latestFeatureId].image02large.default, 'class': 'FeaturesImage', 'width': '100%', 'height': 'auto', 'srcset': featuresContentExports[latestFeatureId].image02small.default + ' 384w, ' + featuresContentExports[latestFeatureId].image02medium.default + ' 1152w, ' + featuresContentExports[latestFeatureId].image02large.default + ' 1920w', 'sizes': '23.333vw'});
        featureImages.appendChild(secondFeatureImage);
      }
      if (featuresContentExports[latestFeatureId].image03large){
        let thirdFeatureImage = document.createElement('img');
        this.setAttributes(thirdFeatureImage, {'src': featuresContentExports[latestFeatureId].image03large.default, 'class': 'FeaturesImage', 'width': '100%', 'height': 'auto', 'srcset': featuresContentExports[latestFeatureId].image03small.default + ' 384w, ' + featuresContentExports[latestFeatureId].image03medium.default + ' 1152w, ' + featuresContentExports[latestFeatureId].image03large.default + ' 1920w', 'sizes': '23.333vw'});
        featureImages.appendChild(thirdFeatureImage);
      }
      if (featuresContentExports[latestFeatureId].featurevideo01){
        let firstFeatureVideo = document.createElement('video');
        this.setAttributes(firstFeatureVideo, {'src': featuresContentExports[latestFeatureId].featurevideo01.default, 'class': 'FeaturesImage', 'width': '100%', 'height': 'auto', 'controls': true});
        featureImages.appendChild(firstFeatureVideo);
      }   
      //reset scroll position
      featureImages.scrollTop = 0;
      document.getElementById('featurecontent').scrollTop = 0;     
    }
    selectedFeatureInit(){
      let featureTitle = this.props.location.pathname.replace(/\/features\//g, '');
      featureTitle = featureTitle.replace(/-/g, ' ');
      let featureDetailsArray = featuresContentExports.find(item => item.title ===featureTitle);
      let featureImages = document.getElementById('featureimages');
      document.getElementById('featurecontenttitle').innerHTML = featureDetailsArray.title;
      document.getElementById('featurecontentauthor').innerHTML = featureDetailsArray.author;
      document.getElementById('featurecontentdate').innerHTML = featureDetailsArray.date;
      document.getElementById('featurecontentbodytext').innerHTML = featureDetailsArray.bodytext;
      featureImages.innerHTML = '';
      if (featureDetailsArray.image01large){
        let firstFeatureImage = document.createElement('img');
        this.setAttributes(firstFeatureImage, {'src': featureDetailsArray.image01large.default, 'class': 'FeaturesImage', 'width': '100%', 'height': 'auto', 'srcset': featureDetailsArray.image01small.default + ' 384w, ' + featureDetailsArray.image01medium.default + ' 1152w, ' + featureDetailsArray.image01large.default + ' 1920w', 'sizes': '23.333vw'});
        featureImages.appendChild(firstFeatureImage);
      }
      if (featureDetailsArray.image02large){
        let secondFeatureImage = document.createElement('img');
        this.setAttributes(secondFeatureImage, {'src': featureDetailsArray.image02large.default, 'class': 'FeaturesImage', 'width': '100%', 'height': 'auto', 'srcset': featureDetailsArray.image02small.default + ' 384w, ' + featureDetailsArray.image02medium.default + ' 1152w, ' + featureDetailsArray.image02large.default + ' 1920w', 'sizes': '23.333vw'});
        featureImages.appendChild(secondFeatureImage);
      }
      if (featureDetailsArray.image03large){
        let thirdFeatureImage = document.createElement('img');
        this.setAttributes(thirdFeatureImage, {'src': featureDetailsArray.image03large.default, 'class': 'FeaturesImage', 'width': '100%', 'height': 'auto', 'srcset': featureDetailsArray.image03small.default + ' 384w, ' + featureDetailsArray.image03medium.default + ' 1152w, ' + featureDetailsArray.image03large.default + ' 1920w', 'sizes': '23.333vw'});
        featureImages.appendChild(thirdFeatureImage);
      }
      if (featureDetailsArray.featurevideo01){
        let firstFeatureVideo = document.createElement('video');
        this.setAttributes(firstFeatureVideo, {'src': featureDetailsArray.featurevideo01.default, 'class': 'FeaturesImage', 'width': '100%', 'height': 'auto', 'controls': true});
        featureImages.appendChild(firstFeatureVideo);
      } 
      //reset scroll position
      featureImages.scrollTop = 0;
      document.getElementById('featurecontent').scrollTop = 0;
    }    
  //load desktop features list
  initFeaturesList(){
    let listContainer = document.getElementById('featureslist');
    let genfeatureslist = document.createElement('ul');
    genfeatureslist.setAttribute('id', 'featuresarchivelist');
    let numberOfListItems = featuresContentExports.length,
    listItem, i;
    listContainer.appendChild(genfeatureslist);
    for (i=numberOfListItems-1; i>=0; i--) {
      listItem = document.createElement('li');
      let title = featuresContentExports[i].title;
      this.setAttributes(listItem, {'class': 'FeaturesListEntry', 'featureid': i });
      listItem.addEventListener("click", this.featureContentSelect.bind(this));
      listItem.innerHTML = title;
      genfeatureslist.appendChild(listItem);
    }
  }
  //Open Individual Desktop Mix Details
  featureContentSelect(e){
    let featureid = e.target.getAttribute('featureid');
    //set new url
    let featureURLExt = featuresContentExports[featureid].title.replace(/\s/g, '-');
    this.props.history.push('/features/' + featureURLExt);
    this.selectedFeatureInit();
  }
  goHome(){
    this.props.history.push('/');
  }  
  //HELPER FUNCTIONS
  setAttributes(el, attrs) {
    for(var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }
}

export default withRouter(DesktopFeatures)


