import React, { Component } from 'react'
import { featuresContentExports } from '../Site-Content/featuresContentArray'
import { mixContentExports } from '../Site-Content/mixContentArray'

export class MobileMixDetails extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
    }
  }
  render() {
    return (
      <div id={'mobilemixlisting' + this.props.mixid} className='MobileMixContentListing' mixid={this.props.mixid}>
        <img id={'mixImage'+this.props.mixid} mixid={this.props.mixid} width='100%' height='auto' src={mixContentExports[this.props.mixid].imagelarge.default} srcSet={mixContentExports[this.props.mixid].imagesmall.default + ' 288w, ' + mixContentExports[this.props.mixid].imagelarge.default + ' 576w'} sizes='90vw' />
        <div className='MobileTitle' id={'mixTitle'+this.props.mixid} mixid={this.props.mixid}> {mixContentExports[this.props.mixid].mixname} </div>
        <div className='MobileMixContentListingBody' id={'mobilemixlistingbody'+this.props.mixid} mixid={this.props.mixid}>
          <button className='MobileMixSelectButton' id={'mobileMixSelect'+this.props.mixid} mixid={this.props.mixid}><div className='mobileMixPlay' mixid={this.props.mixid}/><div class='mobileMixButtonLabel' mixid={this.props.mixid}>LISTEN</div></button>
          <div className='.MobileContentBodyText' id={'mobileBodyText'+this.props.mixid} />
          < p/>
          <div className='MobileArtistLink' id={'mobileartistlink'+this.props.mixid} > {'More from ' + mixContentExports[this.props.mixid].artist} </div>
        </div>      
      </div>
    )
  }
  componentDidMount() {
    let mobileContentListing = document.getElementById('mobilemixlisting' + this.props.mixid);
    mobileContentListing.style.order = mixContentExports.length + featuresContentExports.length - (mixContentExports[this.props.mixid].order - 1);
    document.getElementById('mixImage' + this.props.mixid).addEventListener('click', this.props.mobileContentExtend.bind(this));
    document.getElementById('mixTitle' + this.props.mixid).addEventListener('click', this.props.mobileContentExtend.bind(this));
    document.getElementById('mobileMixSelect' + this.props.mixid).addEventListener('click', (e) => {this.mixAudioSelect(e)});
    document.getElementById('mobileartistlink' + this.props.mixid).addEventListener('click', () => { window.location = mixContentExports[this.props.mixid].artistlink; }, false);
    document.getElementById('mobileBodyText'+this.props.mixid).innerHTML = mixContentExports[this.props.mixid].description;
  }
  componentWillUnmount() {
    document.getElementById('mixImage'+this.props.mixid).removeEventListener('click', this.props.mobileContentExtend.bind(this));
    document.getElementById('mixTitle'+this.props.mixid).removeEventListener('click', this.props.mobileContentExtend.bind(this));
    document.getElementById('mobileartistlink'+this.props.mixid).removeEventListener('click', () => { window.location = mixContentExports[this.props.mixid].artistlink; }, false);
  }
  //Play Selected Mix Audio
  mixAudioSelect(e){
    console.log(e.target)
    let mixid = e.target.getAttribute('mixid');
    this.music = document.getElementById('audiotag');
    //set new audio source
    this.music.pause();
    this.music.src = mixContentExports[mixid].mixfile.default;
    this.props.changeMix(mixid);
  }
}

export default MobileMixDetails
