import React, { Component } from "react";
import './Stylesheets/App.css';
import {mixContentExports} from './Site-Content/mixContentArray';
import BGVis from "./Components/bgVis";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DesktopFeatures from "./Components/desktopFeatures";
import DesktopMixList from "./Components/desktopMixList";
import DesktopMixDetails from "./Components/desktopMixDetails";
import MobileList from "./Components/mobileList";
import BottomBarMenu from "./Components/bottomBarMenu";
import DesktopAudioPlayer from "./Components/desktopAudioPlayer";
import DesktopTwitchPlayer from "./Components/desktopTwitchPlayer";
import {scrollContentExports} from './Site-Content/scrollContentArray';
import { shaders } from './Components/bgVis';

export default class App extends Component {

constructor(props) {
  super(props);
  this.state = {
    //time since page loaded, driving bg anim on desktop
    timer: 0.0,
    //has media player been initialised
    musicInit: false,
    //now playing for scroll
    nowPlayingName: mixContentExports[mixContentExports.length-1].mixname,
    //mobile or desktop
    mobileRule: window.matchMedia('all and (any-hover: none)').matches,
    //current Background Visual Shader
    currentShader: 0,
    //fft analysis
    bass: 0.0,
    mid: 0.0,
    hi: 0.0,
    //
    currentVolume: 1.0,
    //random multiplier seed
    randommult: 0.0,
  }
  //bind functions
  this.runSpectrum = this.runSpectrum.bind(this);
  this.getFrequencyData = this.getFrequencyData.bind(this);
  this.adjustFreqBandStyle = this.adjustFreqBandStyle.bind(this);
  //other misc setup
  this.frequencyBandArray = [...Array(16).keys()];
  
}
render(){  
  return (
  <Router>
    <Switch>
    <div className="Wrapper">
      {/*Mobile Home Page*/}
      <Route path="/m" render={(props) => <MobileList {...props} changeMix={this.changeMix.bind(this)} />} />
      {/*Bottom Bar*/}
      <Route path="/" render={(props) => <div className="BottomBar">
                                          <DesktopAudioPlayer {...props}
                                            musicInit={this.state.musicInit}
                                            initAudioAnalyser={this.initAudioAnalyser.bind(this)}
                                            triggerAnimation={this.triggerAnimation.bind(this)}
                                            moveVolumeIndicator={this.moveVolumeIndicator.bind(this)}
                                            changeVolume={this.changeVolume.bind(this)}
                                            nowPlayingScroll={this.nowPlayingScroll.bind(this)}
                                            currentVolume={this.state.currentVolume}
                                          />
                                          <BottomBarMenu {...props}
                                            resetTimer={this.resetTimer.bind(this)}
                                            stopTimer={this.stopTimer.bind(this)}
                                            mobileRule={this.state.mobileRule}
                                            vh={this.state.vh}
                                          />
                                        </div>}/>
      <div id="desktopwrapper">
        {/*Background Visuals*/}
        <div id="bgvis">
          <BGVis
            bass={this.state.bass}
            mid={this.state.mid}
            hi={this.state.hi}
            timer={this.state.timer}
            randommult={this.state.randommult}
            currentShader={this.state.currentShader} />
        </div>
        {/*Tish Logo*/}
        <img id="trishlogo" src={require("./Site-Content/TrishFrontPageLogo3.gif").default} alt="trish logo" />   
        {/*Live Gif*/}
        <div id="bggif">
          <img src={require("./Site-Content/Icons/LIVE2.gif").default} width="100%" height="auto" />
        </div>
        {/*Routes*/}
          
        {/*Features Page*/}          
        <Route path="/features" render={(props) => <DesktopFeatures {...props} />}/>
        {/*Mixes Page*/}
        <Route exact path="/mixes" render={(props) => <DesktopMixList {...props} changeMixId={this.changeMixId.bind(this)} />}/>
        <Route path="/mixes/:id" render={(props) => <DesktopMixDetails {...props} mixid={this.state.mixid} changeMix={this.changeMix.bind(this)} />}/>          
        {/*Virtual Page*/}
        <Route path="/virtual" component={DesktopTwitchPlayer} />
        <Route path="/shop">
        </Route>      
      </div>
      {/*Audio Source*/}
      <audio 
        id="audiotag"
        src={mixContentExports[mixContentExports.length -1].mixfile.default}
        type="audio/mpeg" 
        preload="auto"
      />      
    </div>    
    </Switch>
  </Router> );  
}
//LIFECYCLE FUNCTIONS
componentDidMount() {
  //check to see if mobile or desktop
  let setMobileRule = window.matchMedia('all and (any-hover: none)').matches;
  this.setState({
    mobileRule: setMobileRule
  }, function() {
    if (this.state.mobileRule){
      //MOBILE
    } else {
      //DESKTOP
      this.setState({shadersObject: Object.entries(shaders), totalShaders: Object.entries(shaders).length});
      //add timing function for bgvis
      this.resetTimer();      
    }
    //link the audio player references
    this.music = document.getElementById('audiotag');
    this.initScroll();
    //resize updater
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    this.updateWindowDimensions();
  });
}
//on window size change
updateWindowDimensions() {
  //update mobile vh
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  this.setState({ vh: vh });
}
//BG VISUAL TIMER FUNCTIONS
resetTimer(){    
  clearInterval(this.myInterval);
  this.myInterval = setInterval(() => { this.setState(prevState => ({ timer: prevState.timer + 0.001 })) }, 10);
}
stopTimer(){
  clearInterval(this.myInterval);
}
//DESKTOP AUDIO ANALYSER FUNCTIONS
initAudioAnalyser = () => {
  this.music.type = 'audio/mpeg';
  var AudioContext = window.AudioContext // Default
    || window.webkitAudioContext // Safari and old versions of Chrome
    || false;
  if (AudioContext) {
      var audioContext = new AudioContext;
  } else {
      // Web Audio API is not supported - Alert the user
      alert("Sorry, but the Web Audio API is not supported by your browser. Please, consider upgrading to the latest version or downloading Google Chrome or Mozilla Firefox");
  } 
  const gainNode = audioContext.createGain();
  const source = audioContext.createMediaElementSource(this.music);
  const analyser = audioContext.createAnalyser();
  //add smoothing here
  analyser.smoothingTimeConstant = 0.99;
  analyser.fftSize = 32;
  source.connect(gainNode);
  gainNode.connect(audioContext.destination);
  source.connect(analyser);
  this.setState({
    musicInit: true,
    audioData: analyser,
    gainData: gainNode,
    audioContext: audioContext,
    randommult: Math.random()
  });
  //start
  requestAnimationFrame(this.runSpectrum);
}
//run desktop audio analyser
runSpectrum(){
  this.getFrequencyData(this.adjustFreqBandStyle);
  requestAnimationFrame(this.runSpectrum);
}
getFrequencyData = (styleAdjuster) => {
  const bufferLength = this.state.audioData.frequencyBinCount;
  const amplitudeArray = new Uint8Array(bufferLength);
  this.state.audioData.getByteFrequencyData(amplitudeArray);
  styleAdjuster(amplitudeArray);
}
adjustFreqBandStyle(newAmplitudeData){
  this.setState({
    bass: newAmplitudeData[0] / 256,
    mid: newAmplitudeData[6] / 256,
    hi: newAmplitudeData[12] / 256,
  });
}
triggerAnimation(){
  requestAnimationFrame(this.runSpectrum);
}
//move volume slider
moveVolumeIndicator(e) {
  var volumeIndicator = document.getElementById('volumeindicator');
  var volumeSlider = document.getElementById('volumeslider');
  var newVolPos = (e.clientY - volumeSlider.getBoundingClientRect().top) / volumeSlider.offsetHeight;
  //if close to top move to top
  if (newVolPos > 0.96) { volumeIndicator.style.top = '96%'; }
  //if close to bottom move to bottom
  if (newVolPos < 0.05) { volumeIndicator.style.top = '0%'; }
  //else move to clicked position
  if (newVolPos < 0.96 && newVolPos > 0.05) { volumeIndicator.style.top = newVolPos*100 + '%'; }
  this.setState({ currentVolume: 1 - newVolPos });
  this.state.gainData.gain.setValueAtTime(this.state.currentVolume, this.state.audioContext.currentTime);
}
changeVolume(vol){
  this.state.gainData.gain.setValueAtTime(vol, this.state.audioContext.currentTime);
}
changeMix = (mixid) => {    
  if(this.state.mobileRule){
    this.setState({
      nowPlayingName: mixContentExports[mixid].mixname
    }, function(){  this.nowPlayingScroll();
                    document.getElementById('pButton').click();
                  });
  } else {
    this.setState({
      randommult: Math.random(),
      currentshader: this.state.shadersObject[this.state.nextshader - 1],
      nowPlayingName: mixContentExports[mixid].mixname
    }, function(){  this.nowPlayingScroll();
                    document.getElementById('pButton').click();
                  });
    if (this.state.nextshader >= this.state.totalshaders) { this.setState({ nextshader: 1 }) }
    else { this.setState({ nextshader: this.state.nextshader + 1 }) }
  }
}
changeMixId = (mixid) => {
  this.setState({ mixid: mixid})
}
//MARQUEE SCROLL FUNCTIONS
//load scrolling text
initScroll(){
  let marqueeScroll = document.getElementById('marqueescroll');
  let spc = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  this.setState({
    scrollText: scrollContentExports[0] + spc + scrollContentExports[1] + spc + scrollContentExports[2] + spc
  }, function(){ marqueeScroll.innerHTML = this.state.scrollText + this.state.scrollText + this.state.scrollText; });
}
//update desktop scrolling text
nowPlayingScroll(){
  let marqueeScroll = document.getElementById('marqueescroll');
  let spc = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  let nowPlaying = "NOW PLAYING: &#127925 " + this.state.nowPlayingName + " &#127925";
  //if on mobile
  if(this.state.mobileRule){
    this.setState({
      scrollText: nowPlaying + spc + scrollContentExports[0] + spc + nowPlaying + spc + scrollContentExports[1] + spc + nowPlaying + spc + scrollContentExports[2] + spc
    }, function(){
        marqueeScroll.innerHTML = this.state.scrollText + this.state.scrollText + this.state.scrollText;
        marqueeScroll.style.animation = 'none';
        let reflow = marqueeScroll.offsetHeight; /* trigger reflow */
        marqueeScroll.style.animation = 'scroll-left-mobile 100s linear infinite';
      });
  //if on desktop
  } else {
    this.setState({
      scrollText: scrollContentExports[0] + spc + nowPlaying + spc + scrollContentExports[1] + spc + scrollContentExports[2] + spc + scrollContentExports[0] + spc + nowPlaying + spc + scrollContentExports[1] + spc + scrollContentExports[2] + spc
    }, function(){
        marqueeScroll.innerHTML = this.state.scrollText + this.state.scrollText + this.state.scrollText;
        marqueeScroll.style.animation = 'none';
        let reflow = marqueeScroll.offsetHeight; /* trigger reflow */
        marqueeScroll.style.animation = 'scroll-left 40s linear infinite';
      }); }
}

//HELPER FUNCTIONS
setAttributes(el, attrs) {
  for(var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

}
